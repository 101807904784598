<template>
  <!-- Register Component -->
  <v-container>
    <v-card color="primary">
      <v-card-title>
        <pagetitle icon="mdi-account-plus" title=" Register "></pagetitle>
      </v-card-title>
      <v-card-text>
        <v-form id='register-form'>
          <v-row dense>
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-text-field color="accent"
                data-cy='username'
                error
                :error-messages="modelState['Username'].join(',  ')"
                label="Username"
                prepend-icon="mdi-account-circle"
                v-model="user.userName"
              />
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-text-field color="accent"
                data-cy='firstname'
                error
                :error-messages="modelState['Firstname'] ? modelState['Firstname'].join(',  ') : ''"
                label="Firstname"
                v-model="user.firstname"
              />
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-text-field color="accent"
                data-cy='lastname'
                error
                :error-messages="modelState['Lastname'] ? modelState['Lastname'].join(',  ') : ''"
                label="Lastname"
                v-model="user.lastname"
              />
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-text-field color="accent"
                data-cy="password"
                label="Password"
                error
                :error-messages="modelState['Password'] ? modelState['Password'].join(',  ') : ''"
                v-model="user.password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                prepend-icon="mdi-lock"
                :type="showPassword ? 'text' : 'password'"
                required
              />
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-text-field color="accent"
                data-cy="confirm"
                error
                :error-messages="modelState['Confirm Password'] ? modelState['Confirm Password'].join(',  ') : ''"
                label="Confirm Password"
                v-model="user.confirmPassword"
                :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                prepend-icon="mdi-lock"
                :type="showPasswordConfirm ? 'text' : 'password'"
                @click:append="showPasswordConfirm = !showPasswordConfirm"
              />
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-text-field color="accent"
                data-cy="email"
                error
                :error-messages="modelState['Email'] ? modelState['Email'].join(',  ') : ''"
                label="Email"
                prepend-icon="mdi-email"
                v-model="user.email"
              />
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-text-field color="accent"
                prepend-icon="mdi-card-account-phone"
                label="Phone"
                v-model="user.phoneNumber"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-spacer></v-spacer>
          <v-btn data-cy='register-btn' outlined @click="onRegister"
            ><v-icon left>mdi-account-plus-outline</v-icon>Register</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn data-cy='reset-btn' outlined @click="onResetPassword"
            ><v-icon left>mdi-lock-reset</v-icon>Reset Password</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn data-cy='forgot-btn' outlined @click="onForgotUsername"
            ><v-icon left>mdi-account-question-outline</v-icon>Forgot
            Username</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn data-cy='login-btn' outlined @click="onLogin"
            ><v-icon left>mdi-login</v-icon>Login</v-btn
          >
          <v-spacer></v-spacer>
        </v-row>
      </v-card-actions>
    </v-card>
    <div>
      <status ref="alert" @closed="onStatusAlertClosed"></status>
    </div>
  </v-container>
</template>

<script>
import apiService from '@/_services/api-service'
import breakpointMixin from "../../mixins/breakpointMixin"
import pagetitle from "../../components/controls/Title"
import router from "@/router"
import store from "@/store"
import status from "../../components/controls/Status"

export default {
  name: "Login",
  components: {
    pagetitle,
    status,
  },
  data: () => ({
    modelState: {
      Username: [],
      Firstname: [],
      Lastname: [],
      Password: [],
      'Confirm Password': [],
      Email: [],
      Phone: [],
    },
    showPassword: false,
    showPasswordConfirm: false,
    user: {
      firstname: "",
      lastname: "",
      userName: "",
      password: "",
      confirmPassword: "",
      email: "",
      phoneNumber: "",
    },
    result: {},
  }),

  mixins: [breakpointMixin],

  methods: {
    onForgotUsername() {
      router.push("/account/forgot");
    },

    onLogin() {
      router.push("/account/login");
    },

    onRegister() {
      const url = 'account/'
      apiService.put(url, this.user, this.successCallback, this.errorCallback)

    },

    successCallback() {
      const notification = {
        title: "User Registration",
        status: "success",
        messages: ["User registration successful"],
      };
      store.commit("setNotification", notification);
      router.push("/account/login");
    },

    errorCallback(error) {
      this.modelState = error.data
      const notification = {
        title: "User Registration",
        status: "error",
        messages: ["Registration failed", "Fix your validation errors"],
      };
      store.commit("setNotification", notification);
    },

    onResetPassword() {
      router.push("/account/reset");
    },

    onStatusAlertClosed(statusCode) {
      if (statusCode === 200) {
        router.push("/account/login");
      }
    },
  },
  router: router,
  store: store,
};
</script>
<style lang="scss" scoped>
.v-card {
  margin-right: 15px;
}
.v-btn {
  margin-bottom: 20px;
  margin-left: 10px;
}
</style>
